@import '../../../assets/common/colors.scss';
@import '../../../assets/common/variables.scss';
@import '../../../assets/common/mixins.scss';

.bread-crumb-container {
    padding: 5px 0;
    .title {
        @include text(400, 14px, 16px, $textColor, 0.7);
        opacity: 0.7;
        padding-right: 5px;
        cursor: pointer;
    }
    .sub-title {
        @include text(400, 14px, 16px, $textColor);
        padding-left: 5px;
        cursor: pointer;
    }
}