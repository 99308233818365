@import "../../../assets/common/partials/spacing";
@import "../../../assets/common/partials/colors";
@import "../../../assets/common/variables.scss";
@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/fonts";
.info-tip-container {
  position: relative;
  /* For tooltip on icons. Still a work in progress. Only right tooltip works */
  .info-tip {
    position: absolute;
    display: inline-block;
    &__right {
      &:before {
        content: attr(data-text); /* here's the magic */
        position: absolute;
        // top: 20%;
        top: 10%;
        transform: translateY(-50%);
        // left: 32%;
        // left: 20%;
        left: 60%;
        margin-left: 15px; /* and add a small left margin */
        width: 200px;
        padding: 10px;
        border-radius: 4px;
        background: #3a434b;
        color: #fff;
        font-size: 12px;
        display: none; /* hide by default */
      }
      &:after {
        content: "";
        position: absolute;

        /* position tooltip correctly */
        // left: 32%;
        // left: 20%;
        left: 60%;
        margin-left: -5px;

        /* vertically center */
        // top: 14%;
        // top: 6%;
        top: 24%;
        transform: translateY(-50%);

        /* the arrow */
        border: 10px solid #3a434b;
        border-color: transparent #3a434b transparent transparent;

        display: none;
      }
      &:hover:before,
      &:hover:after {
        display: block;
        z-index: 3000;
      }
    }

    &__left {
      &:before {
        content: attr(data-text); /* here's the magic */
        position: absolute;
        top: 20%;
        top: 20%;
        transform: translateY(-50%);
        right: 66%;
        margin-right: 15px; /* and add a small left margin */
        width: 200px;
        padding: 10px;
        border-radius: 4px;
        background: #3a434b;
        color: #fff;
        font-size: 12px;
        border: 10px solid #3a434b;
        border-color: transparent #3a434b transparent transparent;
        display: none; /* hide by default */
      }
      &:after {
        content: "";
        position: absolute;

        /* position tooltip correctly */
        right: 66%;
        margin-right: -5px;

        /* vertically center */
        top: 14%;
        transform: translateY(-50%);

        /* the arrow */
        border: 10px solid #3a434b;
        border-color: transparent #3a434b transparent transparent;

        display: none;
      }
      &:hover:before,
      &:hover:after {
        display: block;
      }
    }
  }

  .info-icon {
    color: $spo-600-color;
    height: 13.33px;
    width: 13.33px;
    cursor: pointer;
  }

  .hint {
    width: 185px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $primaryColor;
  }
}
