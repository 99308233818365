@import "../../assets/common/colors.scss";
@import "../../assets/common/variables.scss";
@import "../../assets/common/mixins.scss";

.client-container {
  .input {
    text-align: left !important;
  }
  .ri-search-line {
    position: absolute;
    left: -390px;
    top: -12px;
  }
  tr {
    td:last-child,
    button {
      display: flex;
      align-items: center;
    }
    td {
      max-width: 150px !important;
    }
  }
  .custom-file-input {
    display: none;
  }
  .input__select {
    width: 142px;
    color: $textColor;
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  .input__search {
    border: 1px solid $sideNavHoverColor;
    max-width: 320px;
  }
  .input__search + .input__btn {
    bottom: -12px;
    display: none;
  }
  .input {
    text-align: right;
  }

  @media screen and (max-width: 767px) {
    .ri-search-line {
      position: absolute;
      left: 0;
      top: -12px;
    }
    .button-action {
      padding-left: 7px;
      padding-right: 7px;
      padding-bottom: 0;
      float: right;
      margin-left: 5px;
    }
  }
}
