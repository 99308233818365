@import '../../assets/common/colors.scss';
@import '../../assets/common/variables.scss';
@import '../../assets/common/mixins.scss';

.profile-container {
    .title {
        @include text(600, 16px, 22px, $appColor);
    }

    .value {
        @include text(400, 16px, 22px, $appColor);
    }
       
    .data {
        padding-bottom: 20px;
    }
}