@import '../../../assets/common/colors.scss';
@import '../../../assets/common/variables.scss';
@import '../../../assets/common/mixins.scss';

.application-detail-container{
    .application {
        @include text(600, 25px, 20px, $textColor);
        margin-bottom: 10px;
        display: inline-block;
    }
    .attempts{
        .attempt-status{
           display: flex;
           flex-direction: column;
           align-items: center;
           :first-child {
               margin-bottom: 10px;
           }
        }
        display: flex;
        justify-content: space-between;
        @include text(600, 17px, 20px, $textColor);
    }

    .colored-card-header{
        @include text(600, 15px, 16px, $white);
        background-color: $labelColor;
    }

    .user-info {
        display: flex;
        justify-content: space-between;
        .user-name {
            @include text(600, 14px, 16px, $textColor);
            padding-right: 5px;
        }
        .user-email {
            @include text(400, 13px, 16px, $textColor);
        }
    }
    .user-info .col {
        display: flex;
        align-items: center;
    }
    .list-group-item {
        padding-top: 8px;
        padding-bottom: 8px;
        
    }
}