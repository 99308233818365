.auth-callback {
  height: 100vh;
  width: 100vw;
}

.main {
  width: 30em;
  height: 13.5em;
}

.container {
  display: flex;
  flex-direction: column;
}

.container-item {
  align-self: center;
}
