.new-workflow-container {
  .goback {
    i {
      color: #1e944d;
    }
  }

  .select-input {
    padding: 7px;
  }
  .info-icon {
    color: #d66f0f;
    height: 13.33px;
    width: 13.33px;
    cursor: pointer;
  }

  .hint {
    width: 185px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #3a434b;
  }

  .add-checker {
    height: 24px;
    // width: 75px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: -0.048px;
    color: #399634;
  }

  .remove-checker {
    height: 24px;
    // width: 75px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: -0.048px;
    color: #000;
  }
}
