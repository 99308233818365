@import '../../../assets/common/colors.scss';
@import '../../../assets/common/variables.scss';
@import '../../../assets/common/mixins.scss';


.modal-container{
 .title {
    display: block;
    text-align: center;
    width: 100%;
    padding-left: 32px;
    margin-top: 20px;
    @include text(600, 16px, 24px, $textColor);
    }

    .modal-header {
       border-bottom: none;
       margin-bottom: -15px;
    }

 .otp-field {
  padding: 10px 30px;
 }

 .col-12 {
    margin-bottom: 0;
    padding-bottom: 0;
 }
 .otp-google {
   @include text(400, 16px, 24px, $textColor);
   text-align: center;
   padding-left: 32px;
   padding-right: 32px;
   opacity: 0.5;
 }
 .modal-container {
    width: 482px;
    padding: 32px 40px;
 }
 
  label{
    @include text(400, 14px, 16px, $textColor);
  }
  .modal-footer{
   border-top: none;
   padding: 0 25px 40px 25px;
   margin-top: -15px;
   button{
      width: 100%;
   }
  }
}