@import "../../../assets/common/colors.scss";
@import "../../../assets/common/variables.scss";
@import "../../../assets/common/mixins.scss";

.new-client-container {
  label {
    @include text(normal !important, 14px !important, 20px, $textColor);
  }
  .radio-label,
  .btn-link {
    @include text(normal !important, 14px !important, 20px, $textColor);
  }
  ::placeholder {
    @include text(500, 1px, 20px, $textColor);
  }
  .radio-content {
    display: flex;
    .form-check:first-child {
      margin-right: 50px;
    }
  }
  a,
  .btn-link:hover {
    text-decoration: none;
  }
}
