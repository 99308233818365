@import '../../../../assets/common/colors.scss';
@import '../../../../assets/common/variables.scss';
@import '../../../../assets/common/mixins.scss';


.client-details-container{
  tr {
      td:first-child {
          width: 320px;
       }
       td{
        border: none;
       }
 }
}