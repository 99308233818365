@import "../../assets/common/colors.scss";

.routes {
  padding-top: 140px;
  background-color: $white;
}

.sidebar-fixed {
  position: fixed !important;
  z-index: 4;
}
@media screen and (max-width: 769px) {
  .m-margin {
    margin-top: 20px;
  }
}
