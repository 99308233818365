.selected-client {
  .delete {
    cursor: pointer;

    &:hover {
      i {
        color: black !important;
      }
    }
  }
}

.claim-editor-form {
  .form-control {
    border-radius: 4px !important;
    border: 1px solid #d7dce0 !important;
    box-sizing: border-box !important;
    color: #495057;
    height: 48px !important;
    &:focus {
      border-color: #1e944d !important;
      box-shadow: none !important;
    }

    .was-validated &:invalid,
    &.is-invalid {
      border-color: #dc3545 !important;
    }
  }

  // .ant-picker-focused {
  //   border-color: #1e944d !important;
  //   box-shadow: none !important;
  // }

  .radio-group {
    .form-control {
      border: none !important;
    }
  }
}
