@import "../../assets/common/colors.scss";
@import "../../assets/common/variables.scss";
@import "../../assets/common/mixins.scss";
@import "../../assets/common/partials/variables";
@import "../../assets/common/partials/fonts";
@import "../../assets/common/partials/spacing";


.dashboard-container {
  select {
      width:100px;
      height: 30px;
  }
  .chart-title{
      height: 30px;
  }
  .summary-title {
    @include text(600, 18px, 20px, $textColor);
    padding-bottom: 10px;
  }

 
.sp-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' %3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 0;
    border-radius: $cs-02;
    min-width: 126px;
    max-width: 296px;
   -moz-appearance: none;
   appearance: none;  
   height: 40px;
   padding-left: 13px;
   padding-right: 25px;
}

.large { 
    background-position-y: 8px;
}

.small { 
    background-position-y: 6px;
}

.sp-inputs {
    padding-bottom: 15rem
}

.sp-inputs div:not(:first-child) {
    margin-top: 8rem
}


}