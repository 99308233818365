@import '../../assets/common/colors.scss';
@import '../../assets/common/variables.scss';
@import '../../assets/common/mixins.scss';


.login-page-container {
  background-color: $navyBlue;
  height: 100vh;
  width: 100%;
  .documents{
    width: 100%;
   }
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  .main-content {
    width: 100%;
    height: 80vh;
    
    .col{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .identity-server {
        @include text(600, 56px, 64px, $white);
        text-align: center
      }
      .subtitle {
        @include text(400, 24px, 32px, $white);
        text-align: center;
        padding-top: 24px;
        max-width: 649px;
        padding-bottom: 32px;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-content {
    border-top: 1px solid $white;
    text-align: center;
    @include text(400, 14px, 16px, $white);
    padding: 16px 0 24px 0;
  }
}