@import "../../../../assets/common/colors.scss";
@import "../../../../assets/common/variables.scss";
@import "../../../../assets/common/mixins.scss";

.document-nav-container {
  padding-left: 25px;
  background: $darkish;
  .doc-items {
      display: flex;
      justify-content: flex-start;
      span{
          display: inline-block;
          padding: 10px 30px 10px 0;
          @include text(400, 14px, 22px, $white);
          letter-spacing: 0.4px;
          cursor: pointer;
      }
  }
}

