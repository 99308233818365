.progress-bar-container {
  height: 8px;
  width: 100%;
  background-color: #d7dce0;

  .label {
    padding: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 5px;
  }
}
