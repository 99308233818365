@import '../../../assets/common/colors.scss';
@import '../../../assets/common/variables.scss';
@import '../../../assets/common/mixins.scss';

.new-client-container {
    .new-addon{
        margin-top: -13px;
        cursor: pointer;
        @include text(400, 12px, 20px, $textColor);
    }

   label, .radio-label, .btn-link {
    @include text(600, 15px, 20px, $textColor);
   }
   ::placeholder {
    @include text(500, 1px, 20px, $textColor);
   }
   .radio-content {
       display: flex;
       .form-check:first-child {
           margin-right: 50px;
       }
   }
   a , .btn-link:hover{
       text-decoration: none;
   }
}