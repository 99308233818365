@import "../../assets/common/colors.scss";
@import "../../assets/common/variables.scss";
@import "../../assets/common/mixins.scss";

.authorise-container {
  .enable {
    cursor: pointer;
  }
  tr {
    .sbu-name {
      width: 250px;
    }
  }
  .button-width {
  }

  .content-header {
    display: flex;
    justify-content: space-between;
  }

  .sub-top-btn {
    text-align: right;
    display: inline-block;
  }
  @media screen and (max-width: 767px) {
    display: block;
    .sub-top-btn {
      text-align: right;
      margin-top: 10px;
    }
  }
}

.application-name {
  display: inline-block;
  @include text(400, 14px, 16px, $appColor);
  padding: 3px 6px;
  background-color: $appBgColor;
  border-radius: 3px;
  margin: 5px 5px 5px 0;
  cursor: pointer;
}
