@import '../../../assets/common/colors.scss';
@import '../../../assets/common/variables.scss';
@import '../../../assets/common/mixins.scss';

.transaction-container {
  @include contentPadding;

  .card-content {
    border: 1px solid $offWhite;
    border-radius: 4px;
  }
  .table-responsive {
    border-bottom: 1px solid $offWhite;
    // margin: 0 1rem;
  }
  .action {
    height: 16px;
    left: 16px;
    right: 16px;
    top: calc(50% - 16px / 2);
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    // text-align: center;
    letter-spacing: 0.4px;
    color: $spg-700-color;
  }

  .input__select {
    color: $textColor;
    font-size: 14px;
    letter-spacing: 0.4px;
    width: 142px;
  }

  .input__search,
  .input__select {
    border: 1px solid $offWhite !important;
  }

  .client-select,
  .workflow-select {
    .ant-select-selector {
      height: 48px !important;
    }
  }

  .entry-number {
    .ant-select-selector {
      height: 40px !important;
    }
    .showing {
      font-size: 14px;
      line-height: 16px;
      align-self: center;
      letter-spacing: 0.4px;
      color: $primaryColor;
    }
  }
}
