.scope-container {
  tr {
    td:last-child,
    button {
      display: flex;
      align-items: center;
    }
  }

  #import-scope {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .button-action {
      padding: 10px;
      float: right;
      margin-top: 10px;
    }
  }
}
