@import "../../../../assets/common/colors.scss";
@import "../../../../assets/common/variables.scss";
@import "../../../../assets/common/mixins.scss";

.main-nav-container {
  background: $navyBlue;
  padding-left: 10px;
  padding-right: 10px;
  .ri-menu-line,
  .identity-server,
  .dropdown-toggle {
    color: $white;
  }
  .top-navigation {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .identity-server {
    @include text(600, 24px, 24px, $white);
    text-decoration: none;
  }
  .user-profile {
    display: inline-block;
    .user-name {
      @include text(600, 14px, 16px, $white);
    }
    .user-role {
      @include text(400, 12px, 16px, $offWhite);
      text-align: right;
    }
  }
  .fa-user-circle {
    padding-left: 10px;
    padding-right: 3px;
    font-size: 25px;
  }
  .dropdown-position {
    left: -30px;
  }
  .dropdown-toggle {
    display: flex;
  }
  .dropdown-toggle::after {
    margin-top: 13px;
    font-size: 10px;
  }
  .dropdown-item {
    i {
      padding-right: 10px;
    }
    @include text(500, 14px, 24px, $textColor);
  }

  .error-notification {
    display: flex;
    justify-content: center;
  }
  .navbar-collapse {
    max-width: 165px;
  }

  @media screen and (max-width: 767px) {
    .identity-server {
      @include text(600, 16px, 16px, $white);
    }
    .fa-user-circle {
      color: $white;
      margin-top: 5px;
    }
    #navbarCollapse {
      background-color: $navyBlue;
      right: 0;
      position: fixed;
      width: 1000px;
    }
  }
}
