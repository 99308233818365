.define-workflow-container {
  .goback {
    i {
      color: #1e944d;
    }
  }

  .select-input {
    padding: 7px;
  }
  .info-icon {
    color: #d66f0f;
    height: 13.33px;
    width: 13.33px;
    cursor: pointer;
  }

  .hint {
    width: 185px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #3a434b;
  }

  .add-checker {
    height: 24px;
    // width: 75px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: -0.048px;
    color: #399634;
  }

  .remove-checker {
    height: 24px;
    // width: 75px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: -0.048px;
    color: #000;
  }

  .code-container > .form-group {
    margin-bottom: 0;
  }
  .code-info {
    color: #3a434b;
    line-height: 16px;
  }

  .form-control,
  input[type='text'] {
    border-radius: 4px !important;
    border: 1px solid #d7dce0 !important;
    box-sizing: border-box !important;
    color: #495057 !important;
    height: 48px !important;
    &:focus {
      border-color: #1e944d !important;
      box-shadow: none !important;
    }

    .was-validated &:invalid,
    &.is-invalid {
      border-color: #dc3545 !important;
    }
  }

  // .ant-picker-focused {
  //   border-color: #1e944d !important;
  //   box-shadow: none !important;
  // }

  .radio-group {
    .form-control {
      border: none !important;
    }
  }

  .ant-select-selector {
    height: 48px !important;
  }
}
