.adjust-left {
    padding-left: 10.5px !important;
}

.duration {
    font-size: 14px;
}

.flatpicker-wrapper {
    width: 142px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D7DCE0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 12px;
    color: #3A434B;
    font-size: 14px;
}

.flatpicker-wrapper:focus {
    outline: transparent;
}

.flatpickr-calendar.open {
    // padding: 0 24px 24px 24px;
    background: #ffffff;
    width: 312px;
}

.flatpickr-months {
    padding-top: 16px;
    background: #ffffff;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
    color: #1E944D;
    fill: #1E944D;
}

.flatpickr-months .flatpickr-prev-month {
    padding-left: 54px;
}

.flatpickr-months .flatpickr-next-month {
    padding-right: 54px;
}

.flatpickr-months .flatpickr-month {
    background: #ffffff;
    height: 16px;
}

.flatpickr-current-month {
    background: #fff;
    color: #0D0F11;
    font-size: 14px;
    letter-spacing: 0.4px;
    padding: 0;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: #ffffff; 
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background: #ffffff;
}

.flatpickr-rContainer {
    padding-top: 8px;
}

.flatpickr-weekdays {
    background: #ffffff;
    height: 16px;
    padding-bottom: 10px;
    letter-spacing: 0.2px;
    color: #0D0F11;
}

span.flatpickr-weekday {
    background: #ffffff;
    font-size: 10px;
}

.flatpickr-days {
    border: none;
}

.flatpickr-day {
    max-width: 24px;
    height: 24px;
    line-height: 24px;
    margin: 8px;
}

.dayContainer {
    font-size: 12px;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: #27BE63;
}