.onboard-modal-footer {
    justify-content: flex-start !important;
}

.error-message {
    font-size: 12px;
    color: #E43535;
}

.success-message {
    font-size: 12px;
    color: #1E944D;
}