@import "~normalize.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~remixicon/fonts/remixicon.css";

@import "./assets/common/colors.scss";
@import "./assets/common/variables.scss";
@import "./assets/common/mixins.scss";
@import "./assets/scss/typography.scss";
@import "./assets/scss/input-field.scss";
@import "./assets/scss/tooltips.scss";

/* latin */

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

body {
  margin: 0;
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e944d;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eafbf1;
}

.color-primary {
  color: #34495e;
}

.color-accent {
  color: #32de8a;
}

.color-red {
  color: #ff0000;
}

.color-white {
  color: #ffffff;
}

.bg-primary {
  /* background-color: #34495e; */
  background-color: #013444;
  min-height: 100vh;
}

.content-header {
  @include content-header;
}

.cursor {
  cursor: pointer;
}

.card-content {
  margin-top: 15px;
}

.btn-success {
  background-color: $greenish;
  border: none;
}
.btn-primary {
  background-color: #5d9cec;
  border: none;
}

.nav-tabs {
  .nav-item {
    @include text(500, 14px, 34px, $textColor);
    text-transform: uppercase;
  }
  .nav-link.active {
    @include text(700, 14px, 34px, $greenish);
  }
}

button {
  @include text(600, 16px, 24px, $white);
  padding: 16px 12px;
}

.content-header span:first-child {
  @include text(600, 28px, 32px, $primaryColor);
}

.tab-content {
  padding: 15px 10px;
}

table {
  thead {
    th {
      @include text(600, 14px, 16px, $primaryColor);
      letter-spacing: 0.2%;
      border-bottom: none !important;
    }
    background-color: #f4f5f6;
  }

  tbody {
    tr {
      th,
      td {
        @include text(400, 14px, 16px, $primaryColor);
        letter-spacing: 0.4px;
      }
    }
  }
}
.sub-top-btn {
  display: flex;
}
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  // outline: 0px !important;
  // -webkit-appearance: none;
  box-shadow: none !important;
}

::placeholder {
  font-size: 14px;
}

.green-color {
  color: $greenish;
}
.cursor {
  cursor: pointer;
}

.sp-button--primary-green:disabled {
  background-color: $greenish;
  cursor: not-allowed;
}

.shimmer-line {
  width: 100%;
  height: 8px;
  align-self: center;
  border-radius: 8pxl;
}

.page-nav > li.item-active > a:focus {
  outline: none !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  padding: 10px 10px;
  @include text(400, 14px, 16px, $appColor);
  background: $appBgColor;
  border-radius: 3px;
  border: 0;
  cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: none;
  color: $textColor !important;
  border-radius: 4px;
  border: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: none;
  border: none;
  color: black !important;
}

.dataTables_wrapper {
  @include text(400, 14px, 16px, $appColor);
}

table.dataTable.no-footer {
  border-bottom: 1px solid $sideNavBgColor2;
}

.nav-tabs {
  border-bottom: 1px solid #d7dce0 !important;
  .nav-link {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    opacity: 1;
    color: #0d0f11;
  }
  .nav-link.active {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    opacity: 1;
    color: #166a37;
  }
  .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #166a37;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff;
    border-bottom: 4px solid #1e944d;
  }
  .nav-link {
    border: none;
  }
}

.define-second-step-workflow-container {
  .input > div {
    border-radius: 4px !important;
    border: 1px solid $offWhite !important;
    box-sizing: border-box !important;
    color: #495057;
    min-height: 48px !important;
    &:focus-within {
      border: 1px solid $spg-700-color !important;
    }
  }
}

.css-yk16xz-control {
  height: 40px !important;
}

.css-yk16xz-control {
  border: 1px solid $offWhite !important;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: $spg-300-color !important;
  &:hover {
    border-color: $spg-200-color !important;
  }
}

.ant-select {
  font-size: 16px !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: -0.05px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $spg-700-color !important;
  border-right-width: 1px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $spg-700-color !important;
  border-right-width: 1px !important;
  box-shadow: none !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  border-radius: 4px !important;
  border: 1px solid $offWhite !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  align-self: center !important;
}
.ant-select-selection-placeholder {
  font-size: 16px;
  letter-spacing: -0.05px;
  color: #677684;
}

.ant-select-selection-search-input {
  padding-top: 15px !important;
}

.user-label {
  font-weight: 600;
  letter-spacing: 0.2px;
}
.user-value {
  letter-spacing: -0.048px;
}

.user-value,
.user-label {
  font-size: 16px;
  line-height: 24px;
  color: $primaryColor;
}

.form-control:disabled,
.form-control[readonly] {
  background: #f4f5f6 !important;
  color: #677684 !important;
  letter-spacing: -0.05px !important;
}

.ant-select-disabled {
  .ant-select-selector {
    color: #0d0f11;
  }
}
