@import '../../../../assets/common/colors.scss';
@import '../../../../assets/common/variables.scss';
@import '../../../../assets/common/mixins.scss';

.comment-modal-container {
  .form-control {
    border-radius: 4px !important;
    border: 1px solid $offWhite !important;
    box-sizing: border-box !important;
    color: #495057;
    &:focus {
      border-color: $spg-700-color !important;
      box-shadow: none !important;
    }

    .was-validated &:invalid,
    &.is-invalid {
      border-color: #dc3545 !important;
    }
  }

  .info-icon {
    color: $spo-600-color;
    height: 13.33px;
    width: 13.33px;
    cursor: pointer;
  }

  .hint {
    width: 185px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $primaryColor;
  }
  .item-label {
    width: 35%;
  }
  .item-value {
    width: 65%;
  }
  .item-value,
  .item-label {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $spn-700-color;
  }
  .form-label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #3a434b;
  }

  .modal-header {
    border-bottom: none !important;
  }

  .header-border {
    width: 93%;
    height: 0px;

    /* SPN / 100 */

    border-top: 1px solid #d7dce0 !important;
    align-self: center;
  }
}
