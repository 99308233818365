@import '../../../assets/common/colors.scss';
@import '../../../assets/common/variables.scss';
@import '../../../assets/common/mixins.scss';

.map-application-modal-container{
 .modal-header {
    display: block;
    text-align: left;
    width: 100%;
    @include text(600, 16px, 24px, $textColor);
    }
  label{
    @include text(400, 14px, 16px, $textColor);
  }
}