@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/colors.scss";
.user-groups-container {
  @include contentPadding;

  .card-content {
    border: 1px solid #d7dce0;
    border-radius: 4px;
  }
  .table-responsive {
    border-bottom: 1px solid #d7dce0;
  }
  .action {
    height: 16px;
    left: 16px;
    right: 16px;
    top: calc(50% - 16px / 2);

    /* B300 Line Text 2 */

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    text-align: center;
    letter-spacing: 0.4px;

    /* SPG 700 */

    color: #1e944d;
  }

  .accordion {
    .btn-link {
      color: black;
      font-size: 18px;
      padding: 0;
      &:hover {
        text-decoration: none;
        color: $spg-700-color;
      }
      &:focus {
        text-decoration: none;
        color: $spg-800-color;
      }
    }

    .list-group-item {
      border: none !important;
    }
  }
}
