.row .mb-3 {
    margin: 0;
}

.datepicker-div {
    padding: 0 !important;
    text-align: right;
}

.btn-div {
        padding: 0 !important; 
        padding-left: 10.5px !important;
}

.table-container {
    margin-left: 0 !important;
    margin-right: 0 !important;
}


@media screen and (max-width: 988px){ 
    .datepicker-div {
        margin-top: 10px;
    }
}