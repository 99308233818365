@import '../../assets/common/colors.scss';
@import '../../assets/common/variables.scss';
@import '../../assets/common/mixins.scss';

.users-container {
  @include contentPadding;

  .card-content {
    border: 1px solid #d7dce0;
    border-radius: 4px;
  }
  .table-responsive {
    border-bottom: 1px solid #d7dce0;
    // margin: 0 1rem;
  }
  .action {
    height: 16px;
    left: 16px;
    right: 16px;
    top: calc(50% - 16px / 2);

    /* B300 Line Text 2 */

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    text-align: center;
    letter-spacing: 0.4px;

    /* SPG 700 */

    color: #1e944d;
  }

  .input__select {
    max-width: 142px;
    color: $textColor;
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  .input__search {
    border: 1px solid $sideNavHoverColor;
    max-width: 320px;
  }
  .input__search + .input__btn {
    bottom: -12px;
  }

  .list-group {
    flex-wrap: wrap;
  }
  .list-group-item-action {
    width: calc(100% / 27) !important;
    height: 40px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 17px;

    position: static;
    left: 0px;
    top: 0px;

    box-sizing: border-box;
    border-radius: 0 !important;
    position: static;
    span {
      letter-spacing: -0.05px !important;
      color: #24292e !important;
    }
  }

  .active-item {
    background: #eafbf1 !important;
    border: 1px solid #1e944d !important;
  }

  .no-radius {
    border-radius: 0;
  }

  .ant-input-group {
    &:focus-within {
      border: 1px solid #1e944d !important;
      box-shadow: none !important;
      border-radius: 4px;
      z-index: 1000;

      .ant-input-search-button {
        border: none !important;
      }
    }
  }

  .ant-input-affix-wrapper {
    height: 40px;
    width: 100%;
    color: #000 !important;
    font-size: 16px;
    stroke: #c0f2d4;
    border-radius: 4px 0 0 4px !important;
    border: 1px solid #ccc;
    border-right: none !important;
    padding: 0px 16px;

    &:focus,
    &:focus-visible {
      border: none;
      border-right: none !important;
      box-shadow: none !important;
    }
  }

  .ant-input-affix-wrapper-focused {
    border: none;
    border-right: none !important;
    box-shadow: none !important;
  }

  .ant-input-search-button {
    height: 40px;
    width: 100%;
    color: #000 !important;
    font-size: 16px;
    stroke: #c0f2d4;
    border-radius: 0 4px 4px 0 !important;
    border: 1px solid #ccc;
    border-left: none !important;
    padding: 10px;
  }
}
