@import "../../../assets/common/colors.scss";
@import "../../../assets/common/variables.scss";
@import "../../../assets/common/mixins.scss";

@mixin sidebar-hover($color, $opacity, $borderLeft, $paddingLeft: 10px) {
  background-color: $color;
  opacity: $opacity;
  border-left: $borderLeft;
  padding-left: $paddingLeft;
}
.sidebar-container {
  background-color: $sideNavBgColor2;
  height: 100vh;
  padding-top: 80px;
  .nav-bar {
    padding-top: 20px;
    ul {
      li {
        list-style: none;
      }
    }
    .nav-item {
      max-width: 218px;
      min-height: 38px;
      margin-top: 10px;
      z-index: 1000 !important;
      > a,
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $primaryColor;
        cursor: pointer;
      }
      .nav-link {
        padding-left: 25px;
      }
      .item-name {
        display: flex;
        justify-content: space-between;
        padding-left: 12px;
        min-width: 180px;
        margin-top: 4px;
        @include text(400, 14px, 16px, $textColor);
        span {
          display: inline-block;
          text-align: right;
        }
      }
      .fa {
        padding-left: 10px;
        padding-right: 30px;
        width: 20px;
      }

      .nav-sub-item li {
        max-width: 218px;
        min-height: 38px;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
        width: 126px;
        @include text(400, 14px, 16px, $textColor);
      }
      .nav-sub-item {
        background-color: $sideNavBgColor2;
        margin-left: 45px;
        padding: 0px 10px 0px 8px;
        li {
          padding-left: 0px;
        }
      }
      .show-nav-sub-item {
        display: block;
      }
      .close-nav-sub-item {
        display: none;
      }
      ul {
        list-style: none;
      }
    }
    .nav-collapse:hover {
      width: 180px;
      padding-right: 189px;
      background-color: $sideNavHoverColor;
    }

    .nav-collapse {
      .nav-link {
        padding-left: 15px !important;
      }
    }
    .active {
      color: $textColor;
      z-index: 2000;
      padding-top: 7px;
      padding-bottom: 10px;
      @include sidebar-hover($sideNavHoverColor, 1, 3px solid $greenish, 13px);
    }
  }
}
