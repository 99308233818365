.title-page-container{
    display: flex;
    align-items: center;
    .goback {
        i {
          color: #1e944d;
        }
        cursor: pointer; 
      }
      .title{
          padding-bottom: 3px;
      }
}