.custom-chip-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;

  // minwidth: 228px;
  height: 32px;
  background: #d7dce0;
  border-radius: 16px;

  .remove {
    cursor: pointer;
  }
  // .value {
  //   position: static;
  //   width: 180px;
  //   height: 16px;
  //   left: 12px;
  //   top: 8px;

  //   font-size: 16px;
  //   line-height: 16px;
  //   letter-spacing: -0.05px;
  //   flex: none;
  //   order: 0;
  //   align-self: center;
  //   flex-grow: 0;
  //   margin: 8px 0px;
  // }
}
