@import '../../../../assets/common/colors.scss';
@import '../../../../assets/common/variables.scss';
@import '../../../../assets/common/mixins.scss';

.application-modal-container {
  .ant-select,
  .ant-select-selector {
    height: 48px !important;
  }
  .modal-header {
    border-bottom: none !important;
  }

  .form-control,
  input[type='text'] {
    border-radius: 4px !important;
    border: 1px solid #d7dce0 !important;
    box-sizing: border-box !important;
    color: #495057 !important;
    height: 48px !important;
    &:focus {
      border-color: #1e944d !important;
      box-shadow: none !important;
    }

    .was-validated &:invalid,
    &.is-invalid {
      border-color: #dc3545 !important;
    }
  }

  .header-border {
    width: 93%;
    height: 0px;

    /* SPN / 100 */

    border-top: 1px solid #d7dce0 !important;
    align-self: center;
  }
}
