@import "../../../assets/common/partials/colors";

.edit-item {
  color: $spg-700-color;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
  font-size: 12px;
}

.delete-item {
  color: $spr-600-color;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
}
