@import '../../../assets/common/colors.scss';


.sp-column-notification {
  min-width: 488px;
  max-width: 688px;
  padding: 0 10px;
  justify-self: center;
}
/* Responsive columns */
@media screen and (max-width: 600px) {
  .sp-column-notification {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.section-fix{
    flex-direction: column;
}
/* Style the notification cards */
.sp-confirm-notification {
  padding: 20px;
  text-align: left;
  background-color: $spg-100-color;
  border-style: solid;
  border-width: 1px;
  border-color: $spg-700-color;
  border-radius: 4px;
}
.sp-confirm-notification p{
  margin: 0;
  font-family: Inter;
  color: $spg-600-color;
}
.sp-card-content .sp-notification-ok {
  cursor: pointer;
 background-color: $spg-700-color;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.sp-notification-ok span {
  color: white;
  font-weight: bold;
}

.sp-notification-cancel span {
  cursor: pointer;
  color: $spg-700-color;
  font-weight: 400;
}
.sp-card-content {
  display: flex; 
  justify-content: space-between;
}

.sp-card-content div:nth-child(2) {
  margin-right: auto;
  padding-left: 18px;
}

// Error Notification
.sp-error-notification {
    padding: 10px 20px;
    text-align: left;
    background-color: $spr-100-color;
    border-style: solid;
    border-width: 1px;
    border-color: $spr-700-color;
    border-radius: 4px;
  }
  .sp-error-notification p{
    margin: 0;
    font-family: Inter;
    color: $spr-600-color;
  }

  .sp-error-content .sp-error-close {
    cursor: pointer;
   background-color: $spr-500-color;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
  }

  .sp-error-close span {
    color: white;
    font-weight: bold;
  }

  .sp-error-cancel span {
    cursor: pointer;
    color: $spr-500-color;
    font-weight: 400;
  }

  .sp-error-content {
    display: flex; 
    justify-content: space-between;
  }

  .sp-error-content div:nth-child(2) {
    margin-right: auto;
    padding-left: 18px;
  }
// Warning Notification
.sp-warning-notification {
    padding: 20px;
    text-align: left;
    background-color:$spy-100-color;
    border-style: solid;
    border-width: 1px;
    border-color: $spo-500-color;
    border-radius: 4px;
  }
  .sp-warning-notification p{
    margin: 0;
    font-family: Inter;
    color: $spy-600-color;
  }

  .sp-warning-content .sp-warning-close {
    cursor: pointer;
   background-color: $spo-500-color;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
  }

  .sp-warning-close span {
    color: white;
    font-weight: bold;
  }

  .sp-warning-cancel span {
    cursor: pointer;
    color: $spo-500-color;
    font-weight: 400;
  }

  .sp-warning-content {
    display: flex; 
    justify-content: space-between;
  }

  .sp-warning-content div:nth-child(2) {
    margin-right: auto;
    padding-left: 18px;
  }