@import "../../../assets/common/mixins.scss";
.user-details-container {
  .goback {
    i {
      color: #1e944d;
    }
  }

  .nav-item {
    text-transform: capitalize !important;
  }
  .select-input {
    padding: 7px;
  }
  .info-icon {
    color: #d66f0f;
    height: 13.33px;
    width: 13.33px;
    cursor: pointer;
  }

  .hint {
    width: 185px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #3a434b;
  }

  .add-checker {
    height: 24px;
    // width: 75px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: -0.048px;
    color: #399634;
  }

  .remove-checker {
    height: 24px;
    // width: 75px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: -0.048px;
    color: #000;
  }

  .nav-tabs {
    border-bottom: 1px solid #d7dce0 !important;
    .nav-link {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.2px;
      opacity: 1;
      color: #0d0f11;
    }
    .nav-link.active {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.2px;
      opacity: 1;
      color: #166a37;
    }
    .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      color: #166a37;
      background-color: #ffffff;
      border-color: #dee2e6 #dee2e6 #ffffff;
      border-bottom: 4px solid #1e944d;
    }
    .nav-link {
      border: none;
    }
  }
}
