@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/colors.scss";
.user-clearance-container {
  @include contentPadding;

  .card-content {
    border: 1px solid #d7dce0;
    border-radius: 4px;
  }
  .table-responsive {
    border-bottom: 1px solid #d7dce0;
  }
  .action {
    height: 16px;
    left: 16px;
    right: 16px;
    top: calc(50% - 16px / 2);
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    &-d {
      color: $spr-700-color;
    }
    &-e {
      color: $spg-700-color;
    }
  }
}
