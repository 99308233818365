@import "../../assets//common/mixins.scss";
@import "../../assets//common/colors.scss";

.not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  img {
    display: inline-block;
  }
  .page-not-found {
    @include text(600, 24px, 24px, $primaryColor);
    margin: 16px 0 10px 0;
  }

  .description {
    width: 547px;
    @include text(normal, 16px, 24px, $primaryColor);
    text-align: center;
  }

  .redirect-btn {
    @include text(600, 16px, 24px, $white);
    letter-spacing: 0.2px;
    margin-top: 16px;
  }
}
